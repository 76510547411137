











































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ActasConcejo extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private actasSesion: Array<any> = [];

  private beforeMount() {}
  private mounted() {
    document.title =
      "Actas del Concejo - Ilustre Municipalidad de Punta Arenas";
    this.setMenu();
    this.getActas();
  }

  private getActas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("actas/concejo")
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.actasSesion = res.data;
        for (let i = 0; i < this.actasSesion.length; i++) {
          for (let j = 0; j < this.actasSesion[i].actas_sesion.length; j++) {
            if (this.actasSesion[i].actas_sesion[j].url_archivo) {
              this.actasSesion[i].actas_sesion[j].url_archivo =
                base + this.actasSesion[i].actas_sesion[j].url_archivo;
            }
            if (this.actasSesion[i].actas_sesion[j].certificado) {
              this.actasSesion[i].actas_sesion[j].certificado =
                base + this.actasSesion[i].actas_sesion[j].certificado;
            }
          }
          for (let j = 0; j < this.actasSesion[i].actas_comision.length; j++) {
            if (this.actasSesion[i].actas_comision[j].url_archivo) {
              this.actasSesion[i].actas_comision[j].url_archivo =
                base + this.actasSesion[i].actas_comision[j].url_archivo;
            }
            if (this.actasSesion[i].actas_comision[j].certificado) {
              this.actasSesion[i].actas_comision[j].certificado =
                base + this.actasSesion[i].actas_comision[j].certificado;
            }
          }
          for (
            let j = 0;
            j < this.actasSesion[i].actas_extraordinarias.length;
            j++
          ) {
            if (this.actasSesion[i].actas_extraordinarias[j].url_archivo) {
              this.actasSesion[i].actas_extraordinarias[j].url_archivo =
                base + this.actasSesion[i].actas_extraordinarias[j].url_archivo;
            }
            if (this.actasSesion[i].actas_extraordinarias[j].certificado) {
              this.actasSesion[i].actas_extraordinarias[j].certificado =
                base + this.actasSesion[i].actas_extraordinarias[j].certificado;
            }
          }
          for (let j = 0; j < this.actasSesion[i].documentos.length; j++) {
            if (this.actasSesion[i].documentos[j].url_archivo) {
              this.actasSesion[i].documentos[j].url_archivo =
                base + this.actasSesion[i].documentos[j].url_archivo;
            }
            if (this.actasSesion[i].documentos[j].certificado) {
              this.actasSesion[i].documentos[j].certificado =
                base + this.actasSesion[i].documentos[j].certificado;
            }
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.actasSesion = [];
      });
  }

  private descargarArchivo(url: string) {
    this.win.open(url, "_blank");
    return;
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch(
      "https://admin.puntaarenas.cl/menu.json"
    );
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "actas-del-concejo", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(item: any) {
    if ("actas-del-concejo" == item.nombre_web) {
      item.expand = true;
      return " background-color: #02436b !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if ("actas-del-concejo" == item.sub_menu[i].nombre_web) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: #02436b !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
        }
      }
      return "";
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "actas-del-concejo" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
